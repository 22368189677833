.messageCardMessage a {
  color: #d26d15 !important;
  text-decoration: underline !important;
  font-style: italic;
}

.messageLeft {
  flex: 1;
}

.message-text {
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 0;
}

.example-lightMode {
  background-color: #f4f6fd;
  border: 1px solid #a9bcd3;
}

.example-green {
  background: #0cce9126;
}

.example-grey {
  background: #86909c40;
}

.example-light-blue {
  background: #3365ff26;
}

.example-blue-grey {
  background: #0a122e;
}

.example-red {
  background: #ff2c2c26;
}

.example-purple {
  background: #7e25ff26;
}

.example-orange {
  background: #e67e2226;
}
.message-yellow {
  background: #ffeaaa42;
}
.interviewMainMessage {
  background: #91f9ff3d;
}
.interviewSmallMessage {
  background: #5ed7ff6e;
}
.interviewBlockMessage {
  background: #ffa1ce57;
}
.messageCardMessage p,
.p-nomb p {
  margin-bottom: 0;
}

.badge-event {
  right: 15px;
  bottom: 1px;
}

/* .messageLoadingPlaceHolder {
  background-color: #f6f6f6;
  height: 180px;
  animation: pulse 0.2s linear infinite;
} */

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.tootlip-ticket {
  right: 9px;
  top: 5px;
}
.lightPlaceHolder {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f4f6fd 8%, #fafcff 18%, #f4f6fd 33%);
  background-size: 1000px 104px;
}

.darkPlaceHolder {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #0a122e;
  background: linear-gradient(to right, #0a122e 8%, #1e253d 18%, #0a122e 33%);
  background-size: 1000px 104px;
}

.largeThumPlaceholder {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.smallThumPlaceholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.messageBodPlaceHolder {
  height: 150px;
}

.mention {
  cursor: pointer;
}

.mention.hovered {
  position: relative;
  z-index: 2;
}

.mention.hovered::after {
  content: "";
  position: absolute;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid var(--bs-dark-grey-bg);
  width: 4px;
  height: 4px;
  top: calc(100% + 2px);
  left: 50%;
  transform: translateX(-50%);
}

.mention.hovered.bottom::after {
  top: unset;
  bottom: calc(100% + 2px);
  border-top: 4px solid var(--bs-dark-grey-bg);
  border-bottom: none;
}

.mention.hovered .mention-overlay {
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  max-width: 200px;
  height: 54px;
  background-color: var(--bs-dark-grey-bg);
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  padding: 0.5rem 0.6rem;
}

.mention.hovered.bottom .mention-overlay {
  top: unset;
  bottom: calc(100% + 5px);
}

.mention.hovered .mention-overlay img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.mention.hovered .mention-overlay p {
  text-overflow: ellipsis;
  word-break: keep-all;
  overflow: hidden;
  color: var(--bs-white);
  font-size: 11px;
  font-weight: 500;
}

.mention.hovered .mention-overlay .mention-poste {
  color: var(--bs-light-text);
}
.internalMessageImages {
}
.internalMessageImages img {
  margin-top: 10px;
  max-width: 100%;
  max-width: 300px;
}
