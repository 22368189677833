.textBlueHover:hover {
  transition: all 0.2s ease-in-out;
  color: #3365ff !important;
}
.bgDarkenHover:hover {
  transition: all 0.2s ease-in-out;
  color: #3365ff !important;
}
.scaleHover11:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.1) !important;
}
.opacityHover09:hover {
  transition: all 0.2s ease-in-out;
  opacity: 0.9 !important;
}
.hoverInit {
  transition: all 0.2s ease-in-out;

  transform: scale(0.96) !important;
}
.hoverInit:hover {
  transform: scale(1) !important;
}
