.loginContainer {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.leftSideForm {
  width: 22%;
  display: flex;
  justify-content: flex-end;
  opacity: 0.9;
}
@media (max-width: 1420px) {
  .leftSideForm {
    width: 30% !important;
  }
}
@media (max-width: 1240px) {
  .leftSideForm {
    width: 30% !important;
  }
}
@media (max-width: 700px) {
  .leftSideForm {
    width: 100% !important;
  }
}

.loginInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end !important;
}

.loginImage {
  width: 150px;
}

.loginForm {
  height: 70%;
  width: 100% !important;
}

@media (max-height: 800px) {
  .loginForm {
    height: 75%;
    width: 100% !important;
  }
  .loginImage {
    margin-bottom: 0 !important;
    width: 130px;
  }
}

@media (max-height: 680px) {
  .loginForm {
    height: 85%;
    width: 100% !important;
  }
  .loginImage {
    margin-bottom: 0 !important;
    width: 100px;
  }
}

@media (max-height: 580px) {
  .loginForm {
    height: 87%;
    width: 100% !important;
  }
  .alert {
    font-size: 11px;
  }
}

@media (max-height: 550px) {
  .loginImage {
    margin-bottom: 0 !important;
    width: 80px;
  }
}

@media (max-height: 530px) {
  .loginImage {
    margin-bottom: 0 !important;
    width: 60px;
  }
  .alert {
    font-size: 10px;
  }
}

.rights {
  width: 100%;
  align-self: flex-end !important;
}

.alert-danger {
  background-color: #ff2c2c60;
}

.alert-success {
  background-color: #2ecc7160;
}

.loginInput {
  display: flex;
  background-color: #f4f6fd !important;
  width: 100%;
  outline: none;
  border-radius: 4px;
  border: none;
  height: 45px;
}

.input-content {
  display: flex;
  justify-content: flex-end;
}

.input-content .inputIcons {
  position: absolute;
  margin: 10px;
}

.forgottenPassword {
  align-self: flex-start;
}

.videoLoginContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}
