/* Comment input box */
.comment-input {
    position: absolute;
    width: 240px;
    min-height: 60px;
    background: white;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 1.5;
    color: #374151;
    border: 1px solid rgba(230, 232, 236, 0.8);
    border-radius: 12px;
    z-index: 101;
    resize: vertical;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08),
        0 1px 3px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
    backdrop-filter: blur(8px);
    font-family: inherit;
}

.comment-indicator-user {
    position: absolute;
    right: -41%;
    top: -161%;
}

.comment-input::placeholder {
    color: #9CA3AF;
}

.comment-input:focus {
    outline: none;
    border-color: #3B82F6;
    box-shadow: 0 4px 20px rgba(59, 130, 246, 0.1),
        0 1px 3px rgba(59, 130, 246, 0.05);
}

/* Comment wrapper */
.comment-wrapper {
    position: relative;
}

/* Comment indicator */
.comment-indicator {
    position: absolute;
    width: 28px;
    height: 28px;
    z-index: 102;
    pointer-events: none;
}

.comment-indicator svg {
    width: 100%;
    height: 100%;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

/* Comment marker */
.comment-marker {
    position: absolute;
    width: 28px;
    height: 28px;
    z-index: 101;
    cursor: grab;
}

.comment-marker:active {
    cursor: grabbing;
}

.comment-handle {
    width: 100%;
    height: 100%;
    opacity: 0;
}

/* Comment modal */
.comment-modal {
    cursor: auto;
    position: absolute;
    background: white;
    border-radius: 12px;
    padding: 16px;
    min-width: 200px;
    max-width: 300px;
    z-index: 200;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08),
        0 1px 3px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(8px);
    border: 1px solid rgba(230, 232, 236, 0.8);
    transform-origin: top left;
    animation: modalAppear 0.2s ease-out;
}

.comment-modal::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 12px;
    width: 12px;
    height: 12px;
    background: white;
    border-left: 1px solid rgba(230, 232, 236, 0.8);
    border-top: 1px solid rgba(230, 232, 236, 0.8);
    transform: rotate(45deg);
    z-index: -1;
    transition: all 0.2s ease;
}

/* Modal header */
.comment-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    position: relative;
}

.comment-modal-header p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    color: #374151;
    word-wrap: break-word;
    white-space: pre-wrap;
}

/* Delete button */
.delete-comment-button {
    position: absolute;
    top: -24px;
    right: -24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid rgba(230, 232, 236, 0.8);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transition: all 0.2s ease;
}

.delete-comment-button:hover {
    background-color: #FEE2E2;
    transform: scale(1.05);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}

.delete-comment-button img {
    width: 16px;
    height: 16px;
    opacity: 0.6;
    transition: opacity 0.2s ease;
}

.delete-comment-button:hover img {
    opacity: 1;
}

/* Animation */
@keyframes modalAppear {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}


.pdf-div.commenting-mode,
.pdf-div.commenting-mode .react-pdf__Page,
.pdf-div.commenting-mode .react-pdf__Page__textContent,
.pdf-div.commenting-mode .react-pdf__Page__textContent span {
    cursor: cell !important;
    user-select: none;
}

/* Optional: Disable text selection while in comment mode */
.pdf-div.commenting-mode .react-pdf__Page__textContent {
    pointer-events: none;
}



.commenting-mode .comment-layer {
    z-index: 10;
    cursor: copy;
}

.pdf-div.commenting-mode .react-pdf__Page,
.pdf-div.commenting-mode .react-pdf__Page__textContent,
.pdf-div.commenting-mode .react-pdf__Page__textContent span {
    cursor: cell !important;
    user-select: none;
}

/* Optional: Disable text selection while in comment mode */
.pdf-div.commenting-mode .react-pdf__Page__textContent {
    pointer-events: none;
}



.commenting-mode .comment-layer {
    z-index: 10;
    cursor: copy;
}