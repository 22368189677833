.listHeaderContainer {
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 1;
  position: relative;
}

.listSearchinput {
  border: none;
  outline: none;
}

.darkSearchInput::placeholder {
  color: #a9bcd3;
}

.lightSearchInput::placeholder {
  color: #a9bcd3;
}

.lightSearchInput,
.lightSearchContainer {
  color: #181f3a;
  background-color: white;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 40px;
  width: 40px;
  background: url(../../Images/cancel-icon.svg);
  background-size: cover;
  cursor: pointer;
}

.darkSearchInput,
.darkSearchContainer {
  color: white;
  background-color: #181f3a;
}

.filterCheck {
  width: 20px;
  height: 20px;
}
.listHeaderSelect {
  height: 40px;
  min-width: 220px;
  outline: none;
}
@media (max-width: 992px) {
  .listHeaderSelect {
    height: 35px;
  }
}
