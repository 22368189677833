.open-pdf-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.open-pdf-button:hover {
  background-color: #0056b3;
}

/* Regular modal styling */
.pdf-modal {
  position: relative;
  background: #f4f6fd;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

/* Fullscreen modal styling */
.pdf-modal-fullscreen {
  position: relative;
  background: F4F6FD;
  padding: 20px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  overflow: hidden;
}

.pdf-modal-fullscreen .page-container {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 20px 0;
}

.pdf-modal-fullscreen .pdf-div {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-modal-fullscreen .react-pdf__Page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-modal-fullscreen .react-pdf__Page canvas {
  max-width: 100%;
  max-height: 100% !important;
  width: auto !important;
  height: auto !important;
  object-fit: contain;
}

.pdf-modal-fullscreen .modal-header {
  justify-content: end;
  align-items: center;
  padding: 20px 0;
  position: relative;
}

.pdf-modal-fullscreen .modal-header button {
  height: 50px;
  width: 50px;
}

.pdf-modal-fullscreen .page-controls {
  padding: 15px 0;
  font-size: 1.2em;
  position: relative;
}

.pdf-modal-fullscreen .page-controls button {
  padding: 10px 20px;
  font-size: 1.1em;
  height: auto;
  width: auto;
}

.pdf-overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #181f3a80;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.close-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header button {
  background: #3365ff;
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.modal-header button:hover {
  background: #2957e0;
}

.page-container {
  position: relative;
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.react-pdf__Page {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.react-pdf__Page canvas {
  max-width: 100% !important;
  height: auto !important;
  width: auto !important;
}

.page-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.page-controls button {
  color: #59638b;
  border: none;
  width: 100px;
  height: 30px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.page-controls button:hover {
  background-color: #2957e0;
}

.page-controls button:disabled {
  background-color: #a9bcd3;
  cursor: not-allowed;
}

.pdf-div {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.comment-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
}

.hide-comments-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
}

.comment-button:hover {
  background-color: #218838;
}

.pdf-div {
  position: relative;
  width: fit-content;
  margin: auto;
}

.image-container {
  cursor: default;
}
